import './Sponsors.css';
import React from 'react';
import Footer from '../../Footer/Footer';
import fastX from './SponsorsImg/Fast X Logo.png';
import Learnhill from './SponsorsImg/learnhill logo.png';
import sjvn from './SponsorsImg/SJVN Logo.jpg';
import oil from './SponsorsImg/oil logo.png';
import fundaaz from './SponsorsImg/fundaaz logo.png';
import ednite from './SponsorsImg/ednite logo.png';
import auto from './SponsorsImg/autodesk-logo.jpg';
import fetchAi from './SponsorsImg/fetchai logo.png';
import stpi from './SponsorsImg/stpi logo2.jpg';
import krafton from './SponsorsImg/KRAFTON_Black.png';
import nrl from './SponsorsImg/NRL Logo .jpg';
import ktm from './SponsorsImg/KTM-Logo.svg.png';
import welinnov from './SponsorsImg/welinnov logo.png';
import tooyum from './SponsorsImg/Too yum logo-01.jpg';
import kbom from './SponsorsImg/too yumm kbomb logo.png';
import lotee from './SponsorsImg/lottee Chocopie logo.png';
import coffy from './SponsorsImg/Coffy Bite Logo.png';
import classmate from './SponsorsImg/Classmate Pulse.png';
import max from './SponsorsImg/Max-Protien logo.png';
import lt from './SponsorsImg/language tool logo.png';
import quillbot from './SponsorsImg/Quillbot logo.png';
import yakult from './SponsorsImg/Yakult-Logo.png';
import unstop from './SponsorsImg/Unstop logo.png';
import productfolk from './SponsorsImg/productfolks.png';
import beardo from './SponsorsImg/Beardo Logo Black.png';
import fms from './SponsorsImg/fms logo.png';
import dcx from './SponsorsImg/dcx logo.jpg';
import dyet from './SponsorsImg/dyet nurition logo.jpg';
import revertech from './SponsorsImg/revertech logo.png';
import lipy from './SponsorsImg/Lipy logo.png';
import codingNinja from './SponsorsImg/coding ninja logo.png';
import gentleDentel from './SponsorsImg/gentledental TM logo.png';
import cube from './SponsorsImg/cubelelo logo.jpg';
import witBlox from './SponsorsImg/witboxlogo_1.png';
import kohua from './SponsorsImg/Kohua.JPG';
import gail from './SponsorsImg/gail_logo_web-page.jpg';
import dopamine from './SponsorsImg/dopamine.png';
import labdox from './SponsorsImg/Labdox.png';
import stock from './SponsorsImg/stockedge logo.png';
import easeMytrip from './SponsorsImg/EaseMyTrip Logo.png';
import collab from './SponsorsImg/collab.png';



const Sponsors = () => {
    return ( 
        <div className="sponsor-wrapper">
            <div className="sponsor-head">
                Sponsors
            </div>

            <div className="sponsors-24">
                <div className="companies-24">
                    <a className="companies-anc" href=""><img className="sponsorImg title-sponsor-img"  src={fastX} alt="FastX"></img></a>
                    <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Title Sponsor</div>
                </div>
                <div className="companies-24">
                    <a className="companies-anc" href=""><img className="sponsorImg learnhill-img" src={Learnhill} alt="LearnHill"></img></a>
                    <div className="sponsorname-24" style={{fontSize: '1.2rem', marginTop:'20px'}}>Associate Title Sponsor</div>
                </div>
                <div className="companies-24">
                    <a className="companies-anc" href=""><img className="sponsorImg svjn-img"  src={sjvn} alt="SJVN"></img></a>
                    <div className="sponsorname-24" style={{fontSize: '1.2rem', marginTop:'20px'}}>Presenting Partner</div>
                </div>
                <div className="companies-24">
                    <a className="companies-anc" href=""><img className="sponsorImg oil-img"  src={oil} alt="OIL"></img></a>
                    <div className="sponsorname-24" style={{fontSize: '1.2rem', marginTop:'20px'}}>Co-Presenting Sponsor</div>
                </div>
                <div className="sponsorGroup-24">
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper fundaazimg-24" ><img className="sponsorImg fundaaz-img" src={fundaaz} alt="Fundaaz"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Refreshment Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg ednight-img"  src={ednite} alt="Ednite"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Title sponsor of Technothlon</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper  autoimg-24" ><img className="sponsorImg autodesk-img" src={auto} alt="AutoDesk"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg fetch-img"   src={fetchAi} alt="FetchAi"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Hackathon Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg stp-img"  src={stpi} alt="STPI"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"  ><img className="sponsorImg nrl-img"  src={nrl} alt="NRL"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg krafton-img"  src={krafton} alt="Krafton"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"><img className="sponsorImg ktm-img"  src={ktm} alt="KTM"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Title Sponsor for Autoexpo</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg welin-img"  src={welinnov} alt="Welinnov"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Digital Marketing Partner</div>
                    </div>
                    <div className="companiesGroup-24"  >
                        <div className="sponsorImg-wrapper"><img className="sponsorImg tooyum-img" src={tooyum} alt="Too yum"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Snacking Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"><img className="sponsorImg kbomb-img"   src={kbom} alt="K-bom"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Party Partner</div>
                    </div>
                    <div className="companiesGroup-24"  >
                        <div className="sponsorImg-wrapper"><img className="sponsorImg coffeebite-img" src={coffy} alt="CoffyBite"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Confectionery partner</div>
                    </div>
                    <div className="companiesGroup-24" >
                        <div className="sponsorImg-wrapper"><img className="sponsorImg lotte-img"  src={lotee} alt="Lottee"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Confectionery partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg classmate-img" src={classmate} alt="Classmate"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Stationary Partner of Technothlon</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"   ><img className="sponsorImg sponsorbulk-24 max-img" src={max} alt="MAX-Protien"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Protein Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg lt-img" src={lt} alt="LT"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg quil-img" src={quillbot} alt="QuillBot"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg yakult-img" src={yakult} alt="Yakult"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg unstop-img" src={unstop} alt="UnStop"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg product-img"  src={productfolk} alt="ProductFolks"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"><img className="sponsorImg beardo-img" src={beardo} alt="Beardo"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Grooming Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"><img className="sponsorImg fms-img"src={fms} alt="FMS Delhi"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Innovation Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"><img className="sponsorImg dyet-img" src={dyet} alt="DyetNutrition"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Nutrition Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"><img className="sponsorImg revertech-img" src={revertech} alt="ReverTech"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Workshop Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg dcx-img"  src={dcx} alt="DCX"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>3D Printing Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg lipy-img"  src={lipy} alt="LipyA.I"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Product Partner of Catalyst T and Chatbot Partner of Technothlon</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper"  ><img className="sponsorImg codingnin-img" src={codingNinja} alt="CodingNinjas"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Coding Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg gentleDentel-img"  src={gentleDentel} alt="Gentle Dental"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg Cube-img" src={cube} alt="Cube Lelo"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Event Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg witBlox-img" src={witBlox} alt="WitBlox"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner of Technothlon Mains</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg kohua-img" src={kohua} alt="Kohua Rentals"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Travelling Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg gail-img" src={gail} alt="Gail"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg dopamine-img" src={dopamine} alt="Dopamine"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg labdox-img" src={labdox} alt="Labdox"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper stockimg-24" ><img className="sponsorImg stock-img" src={stock} alt="StockEdge"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg easeMytrip-img" src={easeMytrip} alt="EaseMyTrip"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Travel Partner of Technothlon</div>
                    </div>
                    <div className="companiesGroup-24">
                        <div className="sponsorImg-wrapper" ><img className="sponsorImg collab-img" src={collab} alt="CollabJunction"></img></div>
                        <div className="sponsorname-24" style={{fontSize: '1.2rem'}}>Official Partner</div>
                    </div>
                </div>
                
            </div>

            <div className="sponserFooter-24" style={{marginTop: '10vw'}}>
                <Footer />
            </div>
            
        </div>


     );
}
 
export default Sponsors;